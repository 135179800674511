.client-feedbacks-page {
  .wol-banner {
    background-color: #f1f0f0;
    display: flex;
    justify-content: center;
    padding: 2em 2em;
  }

  #testimonialto-facilitybot-tag-all-light {
    height: 1650px;
    @media (max-width: 2253px) {
      height: 1865px;
    }
    @media (max-width: 1888px) {
      height: 2000px;
    }
    @media (max-width: 1557px) {
      height: 2400px;
    }
    @media (max-width: 1170px) {
      height: 3080px;
    }
    @media (max-width: 802px) {
      height: 5100px;
    }
  }
}

