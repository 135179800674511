.pricing-plans {
	margin: 3em auto;
	overflow: auto;
}

.pricing-structure {
	width: 95vw;
	text-align: center;
	background-color: var(--white);
	border-radius: 1rem;
	box-shadow: 0 8px 18px 1px rgba(0, 0, 0, 0.07);

	.ant-tabs {
		$tabs: '.ant-tabs';
		$tab: '.ant-tabs-tab';
		$tab-animated: '.ant-tabs-ink-bar';
		$tab-btn: '.ant-tabs-tab-btn';
		$nav: '.ant-tabs-nav';
		$tab-animated-z-index: -100;
		overflow: unset;

		&-nav {
			padding: 5rem;

			#{$nav}-wrap {
				overflow: unset;
			}

			#{$nav}-wrap #{$nav}-list {
				border-radius: 1rem;
				border: thin solid #c2c2c2;

				#{$tab} {
					padding: 2.5rem 4rem;
					color: #c2c2c2;
					text-align: center;
					text-transform: uppercase;
					@include font(1.8rem, 2.3rem, 700);

					.psg-grant {
						display: block;
						text-transform: none;
						@include font(1.4rem, 1.8rem, 500);
					}

					&:not(:nth-last-child(2)) {
						border-right: thin solid currentColor;
					}

					&:nth-child(2)::before {
						content: 'save up to 20%';
						position: absolute;
						top: -5rem;
						left: -7.5rem;

						color: var(--primary);
						font-family: var(--promo-font);
						@include font(1.4rem, 2.3rem, 700);
					}

					&:nth-child(2)::after {
						content: '';
						position: absolute;
						top: -4.5rem;
						left: 5rem;

						@include size(3rem);
						background: url('/images/curved-arrow.png') no-repeat;
						background-size: contain;
					}

					&:nth-child(3) {
						.eligibility-link {
							display: unset;

							&::after {
								content: 'View eligibility';
								position: absolute;
								top: 6rem;
								right: -15.5rem;

								color: var(--primary);
								font-size: 1.4rem;
								font-family: var(--promo-font);
								text-transform: capitalize;
								text-decoration: underline;
								cursor: pointer;
							}
						}
					}

					&:nth-child(3)::before {
						content: 'additional 80% grant \00000a for Singapore SMEs';
						position: absolute;
						top: 1rem;
						right: -25rem;

						color: var(--primary);
						white-space: pre;
						text-align: left;
						font-family: var(--promo-font);
						@include font(1.4rem, 2.3rem, 700);
					}

					&:nth-child(3)::after {
						content: '';
						position: absolute;
						top: -1.5rem;
						right: -6.5rem;

						@include size(3rem);
						background: url('/images/curved-arrow.png') no-repeat;
						background-size: contain;
						@include transform(scaleX(-1) rotate(-45deg));
					}

					&.ant-tabs-tab-active #{$tab-btn} {
						color: var(--white);
						font-weight: 700;
					}

					&:first-child.ant-tabs-tab-active ~ #{$tab-animated} {
						border-top-left-radius: 1rem;
						border-bottom-left-radius: 1rem;
					}

					&:nth-last-child(2).ant-tabs-tab-active ~ #{$tab-animated} {
						border-top-right-radius: 1rem;
						border-bottom-right-radius: 1rem;
					}
				}

				#{$tab-animated} {
					height: 100%;
					background: var(--primary);
					z-index: $tab-animated-z-index;
				}
			}
		}

		@include max-width('desktop') {
			& {
				width: 100vw;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
			}

			#{$nav} {
				padding-top: 0;
			}

			#{$nav}-wrap {
				overflow-x: auto;
			}

			#{$tab} #{$tab-btn} .eligibility-link,
			#{$tab}:nth-child(2)::after,
			#{$tab}:nth-child(2)::before,
			#{$tab}:nth-last-child(2)::after,
			#{$tab}:nth-last-child(2)::before {
				display: none !important;
			}
		}
	}
}

.expand-compare-table {
	font-size: 16px;
	text-align: center;
	margin: 20px auto;
	text-decoration: underline;
	color: #ff6337;
	&:hover {
		cursor: pointer;
	}
}

.plan-row {
	top: 10px;
	background-color: white;
	z-index: 110;
	display: inline-flex;
	width: 100%;
	min-width: 1380px;

	&.is-fixed {
		position: fixed;
		width: 95vw;
		left: 2.1vw;
	}

	.plan-column {
		flex: 0 0 25%;
		min-width: 275px;
		border-right: 1px solid #dbdde0;
		text-align: center;
		padding: 10px;
		background: inherit;

		&.plan-item {
			margin-bottom: 30px;
			padding-bottom: 30px;
		}

		.plan-item {
			&--name {
				font-style: normal;
				font-weight: 700;
				font-size: 35px;
			}
			&--price {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ff6337;
				padding-top: 24px;
				.long-number {
					font-size: 45px !important;
				}

				.price-number {
					font-style: normal;
					font-weight: 500;
					font-size: 55px;
					line-height: 55px;
					position: relative;
					.price-currency {
						position: absolute;
						left: -40px;
						bottom: -15px;
						font-weight: 300;
						font-size: 20px;
					}
					.long-currency {
						bottom: -10px !important;
					}

					.price-interval {
						font-size: 16px;
						font-weight: 400;
						position: absolute;
						right: -50px;
						bottom: -35px;
						color: #808080;
						text-transform: capitalize;

						&::before {
							content: '/';
							position: absolute;
							top: -8px;
							left: -8px;
							transform: matrix(-0.78, 2.02, 1.58, -1.74, 2, -1);
						}
					}
				}
			}

			&--interval {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 23px;
				text-align: center;
				color: #7a7a7a;
				margin-top: 30px;
			}

			&--account-number {
				display: flex;
				flex-direction: column;
				align-items: center;
				min-height: 100px;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 50px;
				text-align: center;
				color: #52575c;
				margin-top: 30px;
				margin-bottom: 20px;
			}
		}

		.btn-select-plan {
			color: #ff6337;
			margin-top: 80px;
			border-color: #ff6337;
			width: 14em;
		}

		&.comparison-header {
			background: #fff;
			z-index: 100;
		}

		&.feature-group-name {
			background: #fff4f2;
			z-index: 100;
		}

		&:last-child {
			border-right: 0;
		}
	}

	&.comparison-table {
		z-index: 100;

		&.feature-group {
			background: #fff4f2 !important;
			.feature-group-name {
				background: inherit;
				text-align: left;
				font-size: 20px;
				line-height: 26px;
				color: #ff765d;
			}
		}
		.comparison-header {
			background: #fff;
			text-align: left;
			font-size: 14px;
			line-height: 26px;
			font-weight: 400;
			display: flex;
			align-items: center;
		}
		& .plan-column {
			border-right: none !important;
			i {
				color: var(--primary);
				font-size: 1.7rem;
				cursor: pointer;
			}
		}
		& .plan-column:first-child {
			border-right: 1px solid #dbdde0 !important;
		}
	}
}

.border-bottom {
	border-bottom: 1px solid #dbdde0;
}

.ant-modal .ant-modal-content .ant-modal-body {
	// overflow: auto !important;
	.pricing-structure {
		overflow: auto !important;
		// height: 100% !important;
	}
}
.modal-pricing {
	.ant-modal-close {
		top: -50px;
		left: 50%;
		i {
			font-size: 55px;
		}
		.ant-modal-close-x {
			transform: translateX(-50%);
		}
	}
}

// overwrote active color
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: var(--white);
}
