.mobile-savings-msg {
	position: absolute;
	span {
		color: var(--primary);
		font-family: var(--promo-font);
		position: relative;
    top: 4.9em;
    left: -1.2em;
		font-size: 16px;
		font-weight: 700;
	}
	img {
		position: relative;
    top: 3.8em;
    left: 7em;
	}
}

.pricing-structure-mobile {
	width: 95vw;
	margin: auto;
	overflow: hidden;
	background-color: var(--white);
	border-radius: 1rem;
	box-shadow: 0 8px 18px 1px rgba(0, 0, 0, 0.07);

	.plan-row {
		width: 100%;
		background-color: white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-width: unset;

		.plan-item {
			&--name {
				font-style: normal;
				font-weight: 700;
				font-size: 35px;
				text-align: center;
			}
			&--price {
				display: flex;
				align-items: center;
				justify-content: center;
				color: #ff6337;
				padding-top: 12px;
				.long-number {
					font-size: 45px !important;
				}

				.price-number {
					font-style: normal;
					font-weight: 500;
					font-size: 55px;
					line-height: 55px;
					position: relative;

					.price-currency {
						position: absolute;
						left: -40px;
						bottom: -15px;
						font-weight: 300;
						font-size: 20px;
					}
					.long-currency {
						bottom: -10px !important;
					}

					.price-interval {
						font-size: 16px;
						font-weight: 400;
						position: absolute;
						right: -50px;
						bottom: -35px;
						color: #808080;
						text-transform: capitalize;

						&::before {
							content: '/';
							position: absolute;
							top: -8px;
							left: -8px;
							transform: matrix(-0.78, 2.02, 1.58, -1.74, 2, -1);
						}
					}
				}
			}

			&--interval {
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 23px;
				text-align: center;
				color: #7a7a7a;
				margin-top: 22px;
			}

			&--account-number {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 50px;
				text-align: center;
				color: #52575c;
				margin-top: 12px;
				margin-bottom: 12px;

				.mobile-accounts-num-controllers {
					position: relative;
					height: 1.6rem;
					top: -3.9rem;
					right: -2.2em;
					display: flex;
					flex-direction: column;

					.mobile-accounts-num-controller {
						color: var(--primary);
    				font-size: 28px;
						line-height: 1.7rem;
						
						i {
							line-height: 1rem;
						}

						&:active {
							transform: translateY(-2px);
							color: darkorange;
						}
					}
				}
			}
		}
	}

	.btn.btn--cta-plan {
		width: unset;
	}

	.plan-benefits-rows {
		.plan-column {
			border-right: 1px solid #dbdde0;
			text-align: center;
			padding: 10px;
			background: inherit;
	
			&.comparison-header {
				flex: 0 0 70%;
				background: #fff;
				z-index: 100;
				text-align: left;
				font-size: 14px;
				line-height: 26px;
				font-weight: 400;
				display: flex;
				align-items: center;
			}
		}
	
		.comparison-table {
			display: flex;
			&.feature-group {
				background: #fff4f2 !important;
				.feature-group-name {
					background: inherit;
					text-align: left;
					font-size: 20px;
					line-height: 26px;
					color: #ff765d;
					width: 100%;
				}
			}
			& .plan-column {
				border-right: none !important;
				i {
					color: var(--primary);
					font-size: 1.7rem;
					cursor: pointer;
				}
			}
			& .plan-column:first-child {
				border-right: 1px solid #dbdde0 !important;
			}
		}
	}

	.ant-tabs {
		$tabs: '.ant-tabs';
		$tab: '.ant-tabs-tab';
		$tab-animated: '.ant-tabs-ink-bar';
		$tab-btn: '.ant-tabs-tab-btn';
		$nav: '.ant-tabs-nav';
		$tab-animated-z-index: -100;
		overflow: unset;

		&-nav {
			margin-top: 2em;
			padding: 0 !important;

			&::before {
				border-bottom: none;
			}

			#{$nav}-wrap {
				overflow: unset;
			}

			#{$nav}-wrap #{$nav}-list {
				width: 80%;
				border-radius: 1rem;
				border: thin solid #c2c2c2;

				#{$tab} {
					padding: 2.5rem 4rem;
					color: #c2c2c2;
					text-align: center;
					text-transform: uppercase;
					@include font(1.8rem, 2.3rem, 700);
				}
			}
		}

		&-tab {
			margin: 0;
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
}

// overwrote active color
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: var(--white);
}
